import { Box, Grid } from '@material-ui/core'
import { ResponsivePie } from '@nivo/pie'
import React, { FunctionComponent, useEffect, useState } from 'react'

import CardHeading from '../../components/card-heading/CardHeading'
import { ContainerCard } from '../../components/surfaces/visualization/ContainerCard'

export interface WorkloadCountChartProps {
  height: number;
  getVmList: () => Promise<any>;
  getContainerList: () => Promise<any>;
}

interface IPercentages {
  "Virtual Machines": number;
  "Containers": number;
  [key: string]: number
}

let vmListIntervalId: NodeJS.Timeout;
let containerListIntervalId: NodeJS.Timeout;

export const WorkloadCountChart: FunctionComponent<WorkloadCountChartProps> = ({
  getVmList, getContainerList, height,
  ...props
}) => {
  const [vmList, setVmList] = useState([]);
  useEffect(() => {
    async function loadVms() {
      const theVmList = await getVmList();
      setVmList(theVmList);
    }
    loadVms();
    vmListIntervalId = setInterval(async () => {
      await loadVms();
    }, 60000);
    return () => clearInterval(vmListIntervalId);
  }, []);

  const [containerList, setContainerList] = useState([]);
  useEffect(() => {
    async function loadContainers() {
      const theList = await getContainerList();
      setContainerList(theList);
    }
    loadContainers();
    containerListIntervalId = setInterval(async () => {
      await loadContainers();
    }, 60000);
    return () => clearInterval(containerListIntervalId);
  }, []);

  const [percentages, setPercentages] = useState<IPercentages>({
    "Virtual Machines": 0,
    Containers: 0,
  });
  useEffect(() => {
    const vmCount = vmList?.length || 0;
    const containerCount = containerList?.length || 0;
    const totalCount = vmCount + containerCount;
    const vmPercentageLabel = Math.round((vmCount / totalCount) * 1000) / 10;
    const containerPercentageLabel =
      Math.round((containerCount / totalCount) * 1000) / 10;
    setPercentages({
      "Virtual Machines": vmPercentageLabel,
      Containers: containerPercentageLabel,
    });
  }, [vmList, containerList]);

  const staticText = {
    title: "Workloads Deployed",
  };

  return (
    <ContainerCard {...props}>
      <Box flex={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardHeading title={staticText.title} />
          </Grid>
          <Grid item xs={12} style={{ height }}>
            <ResponsivePie
              data={[
                {
                  id: "Virtual Machines",
                  label: `Virtual Machines (${vmList.length})`,
                  value: vmList.length,
                  color: "#004757",
                },
                {
                  id: "Containers",
                  label: `Containers (${containerList.length})`,
                  value: containerList.length,
                  color: "#00C0EA",
                },
              ]}
              theme={{
                labels: {
                  text: {
                    fontSize: 24,
                    fontWeight: "bold",
                  },
                },
                legends: {
                  text: {
                    fontSize: 18,
                  },
                },
              }}
              margin={{ bottom: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              colors={(d:any) => String(d.color)}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              tooltip={(d: any) => d.label}
              enableRadialLabels={false}
              sliceLabel={(d) => `${percentages[d.id]}%`}
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor={(d) => getLabelColor(d.color)}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: "Virtual Machines",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "Containers",
                  },
                  id: "lines",
                },
              ]}
              legends={[
                {
                  anchor: "bottom",
                  direction: "column",
                  translateY: 56,
                  itemWidth: 175,
                  itemHeight: 22,
                  itemTextColor: "black",
                  symbolSize: 18,
                  symbolShape: "circle",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </ContainerCard>
  );
};

function getLabelColor(hexcolor: string) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
}
