export const projectedCapacityChartMockData = {
  good: [
    {
      x: 5,
      y: 20,
    },
    {
      x: 6,
      y: 30,
    },
    {
      x: 7,
      y: 50,
    },
    {
      x: 8,
      y: 50,
    },
    {
      x: 9,
      y: 60,
    },
    {
      x: 10,
      y: 60,
    },
    {
      x: 11,
      y: 70,
    },
    {
      x: 12,
      y: 80,
    },
  ],
  over: [
    {
      x: 12,
      y: 80,
    },
    {
      x: 13,
      y: 85,
    },
    {
      x: 14,
      y: 95,
    },
    {
      x: 15,
      y: 100,
    },
    {
      x: 16,
      y: 110,
    },
  ],
};
