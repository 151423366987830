import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WebIcon from "@material-ui/icons/Web";
import React, { FunctionComponent } from "react";
import CardHeading from "../card-heading/CardHeading";

export interface NewsFeedProps {
  articles: NewsFeedItemProps[];
}

interface NewsFeedItemProps {
  title: string;
  date: Date;
  url: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(0, 0, 5),
  },
  paper: {
    padding: theme.spacing(2, 1),
  },
  header: {
    padding: theme.spacing(0, 1),
  },
  icons: {
    padding: theme.spacing(0),
  },
  bottomSection: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  newsItems: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  newsItem: {
    margin: theme.spacing(1, 0, 1, 4.75),
  },
  hover: {
    "&:hover": {
      "background-color": "#ebebeb",
      cursor: "pointer",
    },
  },
}));

const staticText = {
  hour: " hour ago",
  hours: " hours ago",
  day: " day ago",
  days: " days ago",
  title: "News",
  readAll: "Go to news",
};

const NewsFeed: FunctionComponent<NewsFeedProps> = (props) => {
  const classes = useStyles();

  const dateFormat = (date: Date) => {
    const hours = Math.abs(new Date().getTime() - date.getTime()) / 3.6e6;

    if (hours < 1) return String(Math.ceil(hours)) + staticText.hour;
    if (hours < 24) return String(Math.ceil(hours)) + staticText.hours;
    if (hours < 48) return String(Math.ceil(hours % 24)) + staticText.day;
    return String(Math.ceil(hours % 24)) + staticText.days;
  };

  // this is a totally fake function at the moment
  const handleClick = (url: string) => {
    console.log(url);
  };

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <CardHeading
          title={staticText.title}
          startIcon={<WebIcon />}
          endIcon={
            <IconButton aria-label="options" className={classes.icons}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <Grid container direction={"column"} className={classes.newsItems}>
          {props.articles?.slice(0, 3)?.map((article, index) => (
            <Grid
              item
              className={classes.hover}
              key={index}
              onClick={() => handleClick(article.url)}
            >
              <Grid className={classes.newsItem}>
                <Typography>{article.title}</Typography>
                <Typography>{dateFormat(article.date)}</Typography>
              </Grid>
            </Grid>
          ))}
          <Divider variant="middle" className={classes.bottomSection} />
          <Grid item className={classes.hover}>
            <Grid
              container
              id={"header"}
              direction={"row"}
              alignItems={"center"}
              className={classes.bottomSection}
              onClick={() => handleClick("all news")}
            >
              <ArrowForwardIcon fontSize="large" />
              <Typography style={{ paddingLeft: 10 }}>
                {staticText.readAll}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default NewsFeed;
