import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { FunctionComponent } from "react";
import CardHeading from "../card-heading/CardHeading";
import MonthlyChargesChart from "../charts/MonthlyChargesChart";
import {
  ContainerCard,
  ContainerCardProps,
} from "../surfaces/visualization/ContainerCard";

export interface MonthlyChargesProps extends ContainerCardProps {
  height: number;
  data: { month: string; amount: number }[];
}

const staticText = {
  title: "Monthly Charges Summary",
  subtitle: "April 2020 - September 2020",
};

export const MonthlyCharges: FunctionComponent<MonthlyChargesProps> = ({
  data,
  height,
  ...props
}) => {
  return (
    <ContainerCard {...props}>
      <Box flex={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardHeading
              title={staticText.title}
              subtitle={staticText.subtitle}
            />
          </Grid>
          <Grid item xs={12} style={{ height }}>
            <MonthlyChargesChart data={data} />
          </Grid>
        </Grid>
      </Box>
    </ContainerCard>
  );
};

export default MonthlyCharges;
