import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelpIcon from "@material-ui/icons/Help";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "gatsby";
import React, { FunctionComponent } from "react";
import CardHeading from "../card-heading/CardHeading";

export interface DocumentationFeedProps {
  documentations: DocumentationFeedItemProps[];
}

interface DocumentationFeedItemProps {
  title: string;
  to?: string;
  external?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 1),
  },
  header: {
    padding: theme.spacing(0, 2),
  },
  icons: {
    padding: theme.spacing(0),
  },
  bottomSection: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  newsItems: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  newsItem: {
    margin: theme.spacing(2, 0, 2, 4.75),
  },
  hover: {
    "&:hover": {
      "background-color": "#ebebeb",
      cursor: "pointer",
    },
  },
}));

const staticText = {
  title: "Documentation",
  readAll: "Go to documentation",
};

const DocumentationFeed: FunctionComponent<DocumentationFeedProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Paper className={classes.paper}>
          <CardHeading
            title={staticText.title}
            startIcon={<HelpIcon />}
            endIcon={
              <IconButton aria-label="options" className={classes.icons}>
                <MoreVertIcon />
              </IconButton>
            }
          />
          <Grid container direction={"column"} className={classes.newsItems}>
            {props.documentations?.slice(0, 3)?.map((documentation, index) => (
              <Grid item className={classes.hover} key={index}>
                <Grid className={classes.newsItem}>
                  <Typography>
                    {documentation.to ? (
                      <Link
                        to={documentation.to}
                        target={documentation.external ? "_blank" : undefined}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {documentation.title}
                      </Link>
                    ) : (
                      documentation.title
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Divider variant="middle" className={classes.bottomSection} />
            <Grid item className={classes.hover}>
              <Grid
                container
                id={"header"}
                direction={"row"}
                className={classes.bottomSection}
                alignItems={"center"}
              >
                <ArrowForwardIcon fontSize="large" />
                <Typography style={{ paddingLeft: 10 }}>
                  {staticText.readAll}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default DocumentationFeed;
