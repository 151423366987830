import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import CardHeading from '../card-heading/CardHeading'
import {
  ContainerCard,
  ContainerCardProps,
} from '../surfaces/visualization/ContainerCard'

export interface CapacityPlanningBarData {
  id: string;
  curr: number;
  currColor: string;
  avg: number;
  max: number;
  maxColor: string;
  capacity: string;
  free?: number;
  freeColor: string;
}

export interface CapacityPlanningBarChartProps extends ContainerCardProps {
  data: CapacityPlanningBarData[];
  height: number;
}

const staticText = {
  title: "Current, max, average utilization over 7 days",
};

export const CapacityPlanningBarChart: React.FC<CapacityPlanningBarChartProps> = ({
  data,
  height,
  ...props
}) => {
  const commonProps = {
    width: 120,
    margin: { top: 5, right: -10, bottom: 35, left: -35 },
    minValue: 0,
    maxValue: 100,
    enableGridY: false,
    axisLeft: null,
    padding: 0.4,
    borderWidth: 1,
    borderColor: "black",
    indexBy: "id",
    keys: ["curr", "free"],
  };

  return (
    <ContainerCard {...props}>
      <Box flex={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardHeading title={staticText.title} />
          </Grid>
          <Grid container direction="row">
            {data.map((barData, index) => {
              const labelValues: any = {
                curr: `${barData.curr}%`,
                max: 'MAX',
                avg: 'AVG',
                free: "",
              };
              const free = 100 - barData.curr;
              const tooltipValues: any = {
                curr: `Current: ${barData.curr}%, Average: ${barData.avg}%, Maximum: ${barData.max}%`,
                free: `Available: ${free}%, Total: ${barData.capacity}`,
              };
              // TODO: Create default
              const barValues = Object.assign({}, barData);
              // Recompute max to avoid double-counting curr
              barValues.max = barValues.max - barValues.curr;
              // Compute free to fill the remaining space on the chart
              barValues.free = 100 - barValues.curr;

              return (
                <Grid
                  item
                  xs
                  style={{ height }}
                  key={`capacity-planning-bar-chart-grid-${index}`}
                >
                  <ResponsiveBar
                    {...commonProps}
                    theme={{
                      labels: {
                        text: {
                          fontSize: 20,
                          fontWeight: "bold",
                        },
                      },
                      axis: {
                        ticks: {
                          text: {
                            fontSize: 18,
                            fontWeight: "bold",
                          },
                        }
                      },
                    }}
                    data={[barValues]}
                    colors={({ id, data }) => data[`${id}Color`]}
                    label={(d: any) => labelValues[d.id]}
                    markers={[
                      {
                        axis: "y",
                        value: barData.avg,
                        lineStyle: {
                          stroke: "rgba(0, 0, 200, .5)",
                          strokeWidth: 2,
                        },
                        legend: labelValues.avg,
                      },
                      {
                        axis: "y",
                        value: barData.max,
                        lineStyle: {
                          stroke: "rgba(200, 0, 0, .5)",
                          strokeWidth: 2,
                        },
                        legend: labelValues.max,
                      },
                    ]}
                    tooltip={({ id }) => tooltipValues[id]}
                    defs={[
                      {
                        id: "fill-striped",
                        type: "patternLines",
                        spacing: 5,
                        rotation: -45,
                        lineWidth: 2,
                        background: "#ffffff",
                        color: "inherit",
                      },
                    ]}
                    fill={[{ match: { id: "free" }, id: "fill-striped" }]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </ContainerCard>
  );
};
