import React from "react"
import { useTranslation } from "react-i18next"

import { Dashboard } from '../components/dashboard/Dashboard'
import Layout from "../components/layout/Layout"
import SEO from "../components/seo/SEO"

const LandingPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("landing.title")} />
        <Dashboard />
    </Layout>
  )
}

export default LandingPage
