import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { costPerProjectData } from "../billing/billing-mock-data";
import { CapacityPlanningBarChart } from "../charts/CapacityPlanningBarChart";
import { projectedCapacityChartMockData } from "../charts/projectedCapacityMockData";
import { CostPerProject } from "../cost-per-project/CostPerProject";
import CurrentBillSnapshot from "../current-bill-snapshot/CurrentBillSnapshot";
import DocumentationFeed from "../documentation-feed/DocumentationFeed";
import { MonthlyCharges } from "../monthly-cost/MonthlyCharges";
import NewsFeed from "../news-feed/NewsFeed";
import { ProjectedCapacity } from "../projected-capacity";
import { ContainerCard } from "../surfaces/visualization/ContainerCard";
import { dashboardMockData } from "./dashboard-mock-data";
import useVirtualizationPage from '../../hooks/useVirtualizationPage';
import { WorkloadCountChart } from '../../components/charts/WorkloadCountChart';

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      height: 3,
    },
  })
);

export const Dashboard: React.FC = () => {
  const {
    articleList,
    documentationList,
    monthlyCharges,
    capacityPlanningBarData,
  } = dashboardMockData;
  const classes = useStyles();

  const {
    getContainerList,
    getVmList,
  } = useVirtualizationPage();

  return (
    <Box flex={1} pl={3} pr={3}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CurrentBillSnapshot {...dashboardMockData.currentBillSnapshot} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <MonthlyCharges height={400} data={monthlyCharges.data}/>
        </Grid>
        <Grid item xs={4}>
          <ContainerCard>
            <CostPerProject data={costPerProjectData} height={400}/>
          </ContainerCard>
        </Grid>
      </Grid>
      <Box mt={3} mb={3}>
        <Divider classes={{ root: classes.divider }}/>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ProjectedCapacity
            good={projectedCapacityChartMockData.good}
            over={projectedCapacityChartMockData.over}
            height={400}
          />
        </Grid>
        <Grid item xs={4}>
          <CapacityPlanningBarChart
            data={capacityPlanningBarData.data}
            height={488}
          />
        </Grid>
        <Grid item xs={4}>
          <WorkloadCountChart
            {...{
              getVmList,
              getContainerList,
            }}
            height={400}
          />
        </Grid>
      </Grid>
      <Box mt={3} mb={3}>
        <Divider classes={{ root: classes.divider }}/>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <NewsFeed articles={articleList}/>
        </Grid>
        <Grid item xs={6}>
          <DocumentationFeed documentations={documentationList}/>
        </Grid>
      </Grid>
    </Box>
  )
};

export default Dashboard;
