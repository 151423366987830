import React from "react";
import { ResponsiveBar } from "@nivo/bar";

export interface MonthlyChargesChartProps {
  data: { month: string; amount: number }[];
}

export const MonthlyChargesChart: React.FC<MonthlyChargesChartProps> = ({
  data,
}) => {
  return (
    <ResponsiveBar
      tooltipFormat={(value) =>
        `$${Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}`
      }
      data={data}
      keys={["amount"]}
      indexBy="month"
      margin={{ top: 50, right: 20, bottom: 50, left: 80 }}
      colors="#00bceb"
      defs={[]}
      fill={[]}
      borderColor={{ from: "color", modifiers: [["darker", 1.4]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: 35,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
        format: "$.2f",
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[]}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 15,
              fontWeight: "bold",
            },
          }
        },
      }}
    />
  );
};

export default MonthlyChargesChart;
